import {ChainId} from '@uniswap/sdk'
import React, {useState} from 'react'
// import {isMobile} from 'react-device-detect'

import styled from 'styled-components'

import Logo from '../../assets/images/swap.png'
import LogoDark from '../../assets/images/swap.png'
import menu from '../../assets/svg/menu.svg'
import {useActiveWeb3React} from '../../hooks'
import {useDarkModeManager} from '../../state/user/hooks'
import {useETHBalances} from '../../state/wallet/hooks'
import {NavLink} from 'react-router-dom'

// import {YellowCard} from '../Card'
import Menu from '../Menu'
import './Header.scss'

import Web3Status from '../Web3Status'

// const AccountElement = styled.div<{ active: boolean }>`
// `
//
// const NetworkCard = styled(YellowCard)`
//   width: fit-content;
//   margin-right: 10px;
//   border-radius: 12px;
//   padding: 8px 12px;
// `
//
//
// const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
//     [ChainId.MAINNET]: null,
//     [ChainId.RINKEBY]: 'Rinkeby',
//     [ChainId.ROPSTEN]: 'Ropsten',
//     [ChainId.GÖRLI]: 'Görli',
//     [ChainId.KOVAN]: 'Kovan'
// }

export default function Header() {
    const {account, chainId} = useActiveWeb3React()

    const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
    const [isDark] = useDarkModeManager()

    const [mobileActive, setMobileActive] = useState(false)

    return (
        <>
            <div id='header'>
                <div className='left'>
                    <NavLink to='/swap' className="button">Swap</NavLink>
                    {/*<NavLink to='./token' className="button">Token</NavLink>*/}
                    <NavLink to='/pool' className="button">Pool</NavLink>
                </div>
                <div className="center">
                    <a href='.'>
                        <img src={isDark ? LogoDark : Logo} alt='logo'/>
                    </a>
                </div>
                <div className='right'>
                    <div className="column">
                        <div className="row">
                            <Menu/>
                            <Web3Status/>
                        </div>
                        {account && userEthBalance ? (
                            <div className='balance' style={{
                                flexShrink: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {userEthBalance?.toSignificant(4)} ZENIQ
                            </div>
                        ) : null}
                    </div>
                    {/*<div>*/}
                    {/*{!isMobile && chainId && NETWORK_LABELS[chainId] &&*/}
                    {/*  <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}*/}
                    {/*</div>*/}
                    {/*<Settings />*/}
                </div>
            </div>
            <div id="mobile-header" className={mobileActive ? 'active' : ''}>
                <div className="top-row">
                    <div id='menu_button' className="menu-button" onClick={e => {
                        if(e.currentTarget === document.getElementById('menu_button')){
                            setMobileActive(!mobileActive)
                        }
                    }}>
                        <img src={menu} alt=""/>
                    </div>
                    <a href='.'>
                        <img src={isDark ? LogoDark : Logo} alt='logo'/>
                    </a>
                </div>
                <div className='bottom'>
                    <NavLink to='/swap' className="button" onClick={() => {
                        setMobileActive(!mobileActive)
                    }}>Swap</NavLink>
                    {/*<NavLink to='./token' className="button">Token</NavLink>*/}
                    <NavLink to='/pool' className="button" onClick={() => {
                        setMobileActive(!mobileActive)
                    }}>Pool</NavLink>
                    <div className="container">
                        <Menu/>
                        <Web3Status/>
                    </div>
                    {account && userEthBalance ? (
                        <div className='balance' style={{
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {userEthBalance?.toSignificant(4)} ZENIQ
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}
