import { Web3Provider } from '@ethersproject/providers'

export default function getLibrary(provider: any): any {
  
  if(window.ethereum){
      const library = new Web3Provider(provider)
      library.pollingInterval = 20000
      return library
  }else{
    const library = new Web3Provider(provider)
    library.pollingInterval = 20000
    return library
  }

}
