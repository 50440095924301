// @ts-nocheck
import {ChainId, JSBI, Percent, Token, WETH, ETHER as etherUniswap} from '@uniswap/sdk'
import {AbstractConnector} from '@web3-react/abstract-connector'
import tupan from '../assets/token/tupan.jpg'

import {injected} from '../connectors'

const chainId = 383414847825
ChainId['MAINNET'] = chainId
ChainId[chainId] = 'MAINNET'
delete ChainId[1]

etherUniswap.name = 'Zeniq'
etherUniswap.symbol = 'ZENIQ'
export const ETHER = etherUniswap

WETH[chainId] = WETH[1]
WETH[chainId].chainId = chainId
WETH[chainId].symbol = 'ZENIQ'
WETH[chainId].name = 'Zeniq'
WETH[chainId].address = '0x74DC1C4ec10abE9F5C8A3EabF1A90b97cDc3Ead8'
delete WETH[1]

export const ZENIQ = WETH[chainId];

export const ROUTER_ADDRESS = '0x7963c1bd24E4511A0b14bf148F93e2556AFe3C27'
// AUTO ROUTER
// export const ROUTER_ADDRESS = '0x8981Cf8f52d63229DCBaad708dC923D433eb89a3'

// a list of tokens by chain
type ChainTokenList = {
    readonly [chainId in ChainId]: Token[]
}

export const TUPAN = new Token(
    ChainId.MAINNET,
    '0x12056546AC468aAd5f9D7Fab9f62D3d97ef95b9A',
    18,
    'TUPAN',
    'Tupan Community Token'
)

export const ILOVESAFIR = new Token(
    ChainId.MAINNET,
    '0xcBd3Dd9a52Beea3D0E4B9356f478e7EEd69438Bf',
    18,
    'SAFIR',
    'I♡SAFIR'
)

export const AVINOC = new Token(
    ChainId.MAINNET,
    '0xF1cA9cb74685755965c7458528A36934Df52A3EF',
    18,
    'AVINOC',
    'AVINOC'
)


// export const CLAPTOKEN = new Token(
//     ChainId.MAINNET,
//     '0x2e15163fBeaCF57f14a8839F309DDADb5ed5E70c',
//     18,
//     '👏👏👏',
//     'Clap Clap'
// )
//
// export const MOONSTONE = new Token(
//     ChainId.MAINNET,
//     '0x782d3A6A9b3Cf0c5380e6613526C3ec1f48726a5',
//     18,
//     'MST',
//     'Moonstone'
// )

// export const GAMBIT = new Token(ChainId.MAINNET, '0xB7d3Aa0fc133F0857Ba58155485A05c527126ED7', 18, 'GMBT', 'Gambit')
// export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
// export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
// export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
// export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
// export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
// export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
    [ChainId.MAINNET]: [ZENIQ]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
    [ChainId.MAINNET]: {}
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
    [ChainId.MAINNET]: []
//     [ChainId.MAINNET]: [ZENIQ]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
    [ChainId.MAINNET]: [ZENIQ]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
    [ChainId.MAINNET]: [
        //[TUPAN, CLAPTOKEN],
        //[GAMBIT, MOONSTONE]
        // [
        //   new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
        //   new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
        // ],
        // [USDC, USDT],
        // [DAI, USDT]
    ]
}

export interface WalletInfo {
    connector?: AbstractConnector
    name: string
    iconName: string
    description: string
    href: string | null
    color: string
    primary?: true
    mobile?: true
    mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    // INJECTED: {
    //   connector: injected,
    //   name: 'Injected',
    //   iconName: 'arrow-right.svg',
    //   description: 'Injected web3 provider.',
    //   href: null,
    //   color: '#010101',
    //   primary: true
    // },
    METAMASK: {
        connector: injected,
        name: 'MetaMask',
        iconName: 'metamask.png',
        description: 'Easy-to-use browser extension.',
        href: null,
        color: '#E8831D'
    },
    // WALLET_CONNECT: {
    //   connector: walletconnect,
    //   name: 'WalletConnect',
    //   iconName: 'walletConnectIcon.svg',
    //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    //   href: null,
    //   color: '#4196FC',
    //   mobile: true
    // // },
    // WALLET_LINK: {
    //   connector: walletlink,
    //   name: 'Coinbase Wallet',
    //   iconName: 'coinbaseWalletIcon.svg',
    //   description: 'Use Coinbase Wallet app on mobile device',
    //   href: null,
    //   color: '#315CF5'
    // },
    // COINBASE_LINK: {
    //   name: 'Open in Coinbase Wallet',
    //   iconName: 'coinbaseWalletIcon.svg',
    //   description: 'Open in Coinbase Wallet app.',
    //   href: 'https://go.cb-w.com/mtUDhEZPy1',
    //   color: '#315CF5',
    //   mobile: true,
    //   mobileOnly: true
    // },
    // FORTMATIC: {
    //   connector: fortmatic,
    //   name: 'Fortmatic',
    //   iconName: 'fortmaticIcon.png',
    //   description: 'Login using Fortmatic hosted wallet',
    //   href: null,
    //   color: '#6748FF',
    //   mobile: true
    // },
    // Portis: {
    //   connector: portis,
    //   name: 'Portis',
    //   iconName: 'portisIcon.png',
    //   description: 'Login using Portis hosted wallet',
    //   href: null,
    //   color: '#4A6C9B',
    //   mobile: true
    // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const WZENIQ = WETH