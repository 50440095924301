import { Currency, CurrencyAmount, JSBI, Token, TokenAmount } from '@uniswap/sdk'
import { ETHER } from "../../constants";
import { useMemo } from 'react'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useAllTokens } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useMulticallContract } from '../../hooks/useContract'
import { isAddress } from '../../utils'
import { useSingleContractMultipleData, useMultipleContractSingleData } from '../multicall/hooks'

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
          .map(isAddress)
          .filter((a): a is string => a !== false)
          .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map(address => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens]
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map(vt => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
            const value = balances?.[i]?.result?.[0]
            const amount = value ? JSBI.BigInt(value.toString()) : undefined
            if (amount) {
              memo[token.address] = new TokenAmount(token, amount)
            }
            return memo
          }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(() => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], [
    currencies
  ])

  const tokenBalances = useTokenBalances(account, tokens)
  const containsETH: boolean = useMemo(() => currencies?.some(currency => currency === ETHER) ?? false, [currencies])
  const ethBalance = useETHBalances(containsETH ? [account] : [])

  return useMemo(
    () =>
      currencies?.map(currency => {
        if (!account || !currency) return undefined
        if (currency instanceof Token) return tokenBalances[currency.address]
        if (currency === ETHER) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  )
}

let balanceFrom: any = null;
let balanceTo: any = null;
export function useCurrencyBalance(account?: string, currency?: Currency, label?: any): CurrencyAmount | undefined {
  console.log(account)
  let myBalance = (useCurrencyBalances(account, [currency])[0]);
  console.log(myBalance)
  if (myBalance && label) {
    if (label.includes('From')) {
      balanceFrom = myBalance;
    } else {
      balanceTo = myBalance;
    }
  }
  return useCurrencyBalances(account, [currency])[0];
}

let getDataFrom = true;
let timerFrom: any = null;
let currencySymbolFrom: any = null;

let getDataTo = true;
let timerTo: any = null;
let currencySymbolTo: any = null;

export async function useCurrencyEur(label?: any, currency?: Currency, callBack?: any) {

  if (label.includes('From')) {
    if (timerFrom)
      clearInterval(timerFrom);

    currencySymbolFrom = null;
    if (currency) {
      currencySymbolFrom = currency!.symbol;
    }
    if (currencySymbolFrom) {
      // if (getDataFrom) {
      //   getDataFrom = false;
      //   await getCurrencyFromApi(callBack, true);
      // }
      timerFrom = setInterval(async function () {
        try {
          if (getDataFrom) {
            getDataFrom = false;
            // if (currencySymbolFrom === "SAFIR") {
            //   let response = await fetch('https://price1.zeniq.services/v1/currentpricelist', {
            //     method: 'POST',
            //     headers: {
            //       'Accept': 'application/json',
            //       'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //       "fiat": "usd",
            //       "symbols": currencySymbolFrom
            //     })
            //   }).then(response => {
            //     return response.json();
            //   }).then(resData => {
            //     getDataFrom = true;
            //     if (balanceFrom) {
            //       try {
            //         callBack(resData[0].usd, balanceFrom, currencySymbolFrom, "From");
            //       } catch (e) {
            //         callBack(undefined, undefined, currencySymbolFrom, "From");
            //       }
            //     } else {
            //       callBack(undefined, undefined, currencySymbolFrom, "From");
            //     }
            //   }).catch((error) => {
            //     getDataFrom = true;
            //     callBack(undefined, undefined, currencySymbolFrom, "From");
            //   });
            // } else {

            await getCurrencyFromApi(callBack, false);
            // let response = await fetch('https://price2.zeniq.services/v1/currentprice/' + currencySymbolFrom + '/usd').then(response => {
            //   return response.json();
            // }).then(resData => {
            //   getDataFrom = true;
            //   if (balanceFrom) {
            //     try {
            //       callBack(resData.usd, balanceFrom, currencySymbolFrom, "From");
            //     } catch (e) {
            //       callBack(undefined, undefined, currencySymbolFrom, "From");
            //     }
            //   } else {
            //     callBack(undefined, undefined, currencySymbolFrom, "From");
            //   }
            // }).catch((error) => {
            //   getDataFrom = true;
            //   callBack(undefined, undefined, currencySymbolFrom, "From");
            // });

            // }
          }
          return;
        } catch (e) {
          getDataFrom = true;
          callBack(undefined, undefined, currencySymbolFrom, "From");
          return undefined;
        }
      }, 1000);
    }
  } else {
    if (timerTo)
      clearInterval(timerTo);
    currencySymbolTo = null;
    if (currency) {
      currencySymbolTo = currency!.symbol;
    }
    if (currencySymbolTo) {
      // if (getDataTo) {
      //   getDataTo = false;
      //   await getCurrencyToApi(callBack, true);
      // }
      timerTo = setInterval(async function () {
        try {
          if (getDataTo) {
            getDataTo = false;
            // if (currencySymbolTo === "SAFIR") {
            //   let response = await fetch('https://price1.zeniq.services/v1/currentpricelist', {
            //     method: 'POST',
            //     headers: {
            //       'Accept': 'application/json',
            //       'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //       "fiat": "usd",
            //       "symbols": currencySymbolTo
            //     })
            //   }).then(response => {
            //     return response.json();
            //   }).then(resData => {
            //     getDataTo = true;
            //     if (balanceTo) {
            //       try {
            //         callBack(resData[0].usd, balanceTo, currencySymbolTo, "To");
            //       } catch (e) {
            //         callBack(undefined, undefined, currencySymbolTo, "To");
            //       }
            //     } else {
            //       callBack(undefined, undefined, currencySymbolTo, "To");
            //     }
            //   }).catch((error) => {
            //     getDataTo = true;
            //     callBack(undefined, undefined, currencySymbolTo, "To");
            //   });
            // } else {

            await getCurrencyToApi(callBack, false);
            // let response = await fetch('https://price2.zeniq.services/v1/currentprice/' + currencySymbolTo + '/usd').then(response => {
            //   return response.json();
            // }).then(resData => {
            //   getDataTo = true;
            //   if (balanceTo) {
            //     try {
            //       callBack(resData.usd, balanceTo, currencySymbolTo, "To");
            //     } catch (e) {
            //       callBack(undefined, undefined, currencySymbolTo, "To");
            //     }
            //   } else {
            //     callBack(undefined, undefined, currencySymbolTo, "To");
            //   }
            // }).catch((error) => {
            //   getDataTo = true;
            //   callBack(undefined, undefined, currencySymbolTo, "To");
            // });

            // }
          }
          return;
        } catch (e) {
          getDataTo = true;
          callBack(undefined, undefined, currencySymbolTo, "To");
          return undefined;
        }
      }, 1000);
    }
  }

}

async function getCurrencyFromApi(callBack: any, firstFlag: any) {
  if(firstFlag)
    await delay(1000);

  let response = await fetch('https://price2.zeniq.services/v1/currentprice/' + currencySymbolFrom + '/usd').then(response => {
    return response.json();
  }).then(resData => {
    getDataFrom = true;
    console.log(resData);
    console.log(balanceFrom);
    if (balanceFrom) {
      try {
        if (resData.symbol.toLowerCase() == currencySymbolFrom.toLowerCase()) {
          callBack(resData.usd, balanceFrom, currencySymbolFrom, "From");
        } else {
          callBack(undefined, undefined, currencySymbolFrom, "From");
        }
      } catch (e) {
        callBack(undefined, undefined, currencySymbolFrom, "From");
      }
    } else {
      callBack(undefined, undefined, currencySymbolFrom, "From");
    }
  }).catch((error) => {
    getDataFrom = true;
    callBack(undefined, undefined, currencySymbolFrom, "From");
  });
}

async function getCurrencyToApi(callBack: any, firstFlag: any) {
  if(firstFlag)
    await delay(1000);

  let response = await fetch('https://price2.zeniq.services/v1/currentprice/' + currencySymbolTo + '/usd').then(response => {
    return response.json();
  }).then(resData => {
    getDataTo = true;
    if (balanceTo) {
      try {
        if (resData.symbol.toLowerCase() == currencySymbolTo.toLowerCase()) {
          callBack(resData.usd, balanceTo, currencySymbolTo, "To");
        } else {
          callBack(undefined, undefined, currencySymbolTo, "To");
        }
      } catch (e) {
        callBack(undefined, undefined, currencySymbolTo, "To");
      }
    } else {
      callBack(undefined, undefined, currencySymbolTo, "To");
    }
  }).catch((error) => {
    getDataTo = true;
    callBack(undefined, undefined, currencySymbolTo, "To");
  });
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}
