import React, { Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import './App.scss'
import '../globals.scss'


export default function App() {
  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <div className='appwrapper'>
            <Header />
          <div className='bodywrapper'>
            <Popups />
            <Web3ReactManager>
              <Switch>
                <Route exact strict path='/swap' component={Swap} />
                <Route exact strict path='/swap/:outputCurrency' component={RedirectToSwap} />
                <Route exact strict path='/send' component={RedirectPathToSwapOnly} />
                <Route exact strict path='/find' component={PoolFinder} />
                <Route exact strict path='/pool' component={Pool} />
                <Route exact strict path='/create' component={RedirectToAddLiquidity} />
                <Route exact path='/add' component={AddLiquidity} />
                <Route exact path='/add/:currencyIdA'
                       component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path='/add/:currencyIdA/:currencyIdB'
                       component={RedirectDuplicateTokenIds} />
                <Route exact strict path='/remove/:tokens'
                       component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path='/remove/:currencyIdA/:currencyIdB'
                       component={RemoveLiquidity} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
          </div>
        </div>
      </HashRouter>
    </Suspense>
  )
}
